export enum Width {
    Size8 = 8,
    Size10 = 10,
    Size12 = 12,
    Size15 = 15,
    Size18 = 18,
    Size20 = 20,
    Size22 = 22,
    Size25 = 25,
    Size30 = 30,
  };