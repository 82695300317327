import { Mesh, Vector3, Scene, MeshBuilder, CSG } from '@babylonjs/core';

export default class MeshCutter {
    static sliceMesh(mesh:Mesh, positions:Vector3[], scene:Scene) : Mesh {
        positions.push(positions[0]);  //close profile
        var direction = [
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 1)
        ];
        var triangle = MeshBuilder.ExtrudeShape("ExtrusionShape",  {shape: positions, path: direction, cap: Mesh.CAP_ALL, sideOrientation: Mesh.DOUBLESIDE}, scene);
        triangle.position = new Vector3(0, 0, -0.5);
        mesh.setAbsolutePosition(new Vector3(0, 0,0));
        
        var meshCSG = CSG.FromMesh(mesh);
        var slicerCSG = CSG.FromMesh(triangle);
        
        var newMesh = meshCSG.intersect(slicerCSG).buildMeshGeometry(mesh.name + "_Cut", scene);
        newMesh.createNormals(false);
        triangle.dispose();
        newMesh.material = mesh.material;
        return newMesh;
    }
}
