import ShedMeshComposer from './shedMeshComposer';
import ShedMeshSupplier from './shedMeshSupplier';
import { Width } from './Enums/width';
import ShedMaterialSupplier from './shedMaterialSupplier';
import { ShedType as ShedType } from './Enums/shedType';
import SceneManager from './sceneManager';
import { WallType } from './Enums/wallType';
import { SmallWallType } from './Enums/smallWallType';
import MeshCutter from './meshCutter';
import FacadeElement from './facadeElement';
import { OutlineDirection } from './Enums/outlineDirection';
import { PositioningDirection } from './Enums/positioningDirection';
import { PlaceableMeshType } from './Enums/placeableMeshes';
import { HighLightType } from './Enums/highlightType';
import UtilityFunctions from './utilityFunctions';
import { RoofType } from './Enums/roofType';
import OptimizationManager from './optimizationManager';
import { GutterOption } from './Enums/gutterOption';
import { SmallWallMaterial } from './Enums/smallWallMaterial';
import { AbstractMesh, ActionManager, ArcRotateCamera, Color3, Engine, EngineInstrumentation, ExecuteCodeAction, HighlightLayer, Mesh, MeshBuilder, PointerDragBehavior, Scene, SceneInstrumentation, ShadowGenerator, StandardMaterial, Tools, Vector3 } from '@babylonjs/core';
import { AdvancedDynamicTexture, Control, RadioGroup, SelectionPanel, SliderGroup, StackPanel, TextBlock } from '@babylonjs/gui';
import { GLTF2Export } from '@babylonjs/serializers';

export default class ConfiguratorScene {

    private canvas: HTMLCanvasElement;
    private engine: Engine;
    private scene: Scene;

    private shedMeshComposer: ShedMeshComposer;
    private shedMeshSupplier: ShedMeshSupplier;
    private shedMaterialSupplier: ShedMaterialSupplier;
    private sceneManager: SceneManager;
    private highLightLayer: HighlightLayer;
    private optimizationManager: OptimizationManager;
    private updateScenePromise:Promise<void> = null;

    private shedPosition: Vector3 = new Vector3(0, 0, 0);

    public readonly apiUrl = "https://testapihalconfig.twinvision.nl";//"https://localhost:44314"; //


    private outlineColor: Color3;
    private selectedMesh: AbstractMesh;
    private outlineWidth = 0.02;
    private outlineCorner = 0.1;


    constructor(canvasElement: string) {
        // Create canvas and engine.
        this.canvas = document.getElementById(canvasElement) as unknown as HTMLCanvasElement;
        this.engine = new Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true });
        this.scene = new Scene(this.engine);
    
        this.outlineColor = new Color3(0.14, 0.49, 0.7);
    }
  
 applyOutline(mesh: Mesh) {

    var orientateY = function(angle) {
		mesh.rotation.y = angle;
	}

    var displayValue = function(value) {
        return Tools.ToDegrees(value) | 0;
    }

    var setColor = (but) => {  
        var blueMat = new StandardMaterial("blue", this.scene);
        blueMat.emissiveColor = new Color3(0,0,1);
        
        var redMat = new StandardMaterial("red", this.scene);
        redMat.emissiveColor = new Color3(1,0,0); 
		switch(but) {
            case 0: 
            mesh.material = blueMat;
            break
            case 1: 
            mesh.material = redMat;
            break
        }
	}

    const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

    const selectBox = new SelectionPanel("selectBox");
    selectBox.width = 0.25;
    selectBox.height = 0.48;
    selectBox.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    selectBox.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    advancedTexture.addControl(selectBox);

	var colorGroup = new RadioGroup("Color");
	colorGroup.addRadio("Blue", setColor, true);
    colorGroup.addRadio("Red", setColor, false);
	
	var rotateGroup = new SliderGroup("Rotation");
	rotateGroup.addSlider("Angle", orientateY, "degs", 0, 2 * Math.PI, 0, displayValue) 

    selectBox.addGroup(colorGroup);
    selectBox.addGroup(rotateGroup);

      if (this.selectedMesh) {
        this.selectedMesh.renderOutline = false;
        this.selectedMesh.material.alpha = 1;
      }
  
      mesh.renderOutline = true;
      mesh.outlineColor = this.outlineColor;
      mesh.outlineWidth = this.outlineWidth;
      //mesh.outlineCorner = this.outlineCorner; 
      mesh.material.alpha = 0.5;
      this.selectedMesh = mesh;
  }
  
 behaviorClick(object: Mesh) {
      object.actionManager = new ActionManager(this.scene);
      object.actionManager.registerAction(
          new ExecuteCodeAction(ActionManager.OnPickTrigger, (evt) => {
            this.applyOutline(object);
          })
      );
  }

 
 restoreMaterial() {
      if (this.selectedMesh) {
        this.selectedMesh.material.alpha = 1;
      }
  }
  
 behaviourDrag(object: Mesh, stoppers) {
      var pointerDragBehavior = new PointerDragBehavior({ dragPlaneNormal: new Vector3(1, 1, 1) });
      pointerDragBehavior.useObjectOrientationForDragging = false;
      //pointerDragBehavior.useObjectOrienationForDragging = false;
      pointerDragBehavior.updateDragPlane = false;
      pointerDragBehavior.validateDrag = (newPos) => {
          const oldAbs = object.getAbsolutePosition().clone();
          object.setAbsolutePosition(newPos);
          object.computeWorldMatrix(true);
          const stopDrag = (stoppers || []).every(stopper => {
              return (object.intersectsMesh(stopper, true))
          });
           object.setAbsolutePosition(oldAbs);
           object.computeWorldMatrix(true);
          return !stopDrag;
      }
      object.addBehavior(pointerDragBehavior);
  
      pointerDragBehavior.onDragStartObservable.add(() => {
        this.applyOutline(object);
      });
  
      pointerDragBehavior.onDragEndObservable.add(() => {
        this.restoreMaterial();
      });
  }

    async createScene(debug: boolean = false, profile: boolean = false) {
        // Create a basic BJS Scene object.
        this.scene = new Scene(this.engine);
        this.sceneManager = new SceneManager();

        this.highLightLayer = new HighlightLayer("hl1", this.scene);

        var generateShadows = true;
        await this.sceneManager.BuildScene(this.engine, this.shedMeshSupplier, this.scene, this.canvas, generateShadows);

        var shadowGenerator = generateShadows ? (this.sceneManager.shadowGenerator as ShadowGenerator) : null;
        if(shadowGenerator !== null){
            shadowGenerator.setDarkness(0.5);
        }
        if (debug) {
            this.scene.debugLayer.show({
                embedMode: true,
            });
        }

        this.shedMaterialSupplier = new ShedMaterialSupplier(this.scene);
        await this.shedMaterialSupplier.LoadMaterials(this.scene);

        this.shedMeshSupplier = new ShedMeshSupplier(this.shedMaterialSupplier, this.sceneManager);
        await this.shedMeshSupplier.LoadMeshes(this.scene);

        //this.sceneManager.BuildEnvironment(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene);
        this.shedMeshComposer = new ShedMeshComposer(this.engine, this.shedPosition, this.scene, this.sceneManager, this.highLightLayer);
        if (debug) {
            //let facadeElements = this.bigAmountOfFacadeElements();
        }

        if (profile) {
            this.performanceProfilers();
        }

        //this.optimizationManager = new OptimizationManager(this.shedMeshComposer, this.sceneManager, this.engine, this.scene);
    }

    ArcAnimation(toAlpha, toBeta) {
        throw new Error("Method not implemented.");
        
        // var animCamAlpha = new Animation("animCam", "alpha", 30,
        //     Animation.ANIMATIONTYPE_FLOAT,
        //     Animation.ANIMATIONLOOPMODE_CYCLE);

        // let fromValue = (this.scene.activeCamera as ArcRotateCamera).alpha % (Math.PI * 2);
        // if (fromValue < 0) {
        //     fromValue += (Math.PI * 2);
        // }
        // if (Math.abs(fromValue - toAlpha) > Math.PI) {
        //     if (fromValue < toAlpha) {
        //         toAlpha -= Math.PI * 2;
        //     }
        //     else {
        //         toAlpha += Math.PI * 2;
        //     }
        // }

        // var keysAlpha = [];
        // keysAlpha.push({
        //     frame: 0,
        //     value: fromValue
        // });
        // keysAlpha.push({
        //     frame: 50,
        //     value: toAlpha
        // });

        // var animCamBeta = new Animation("animCam", "beta", 30,
        //     Animation.ANIMATIONTYPE_FLOAT,
        //     Animation.ANIMATIONLOOPMODE_CYCLE);

        // var keysBeta = [];
        // keysBeta.push({
        //     frame: 0,
        //     value: (this.scene.activeCamera as ArcRotateCamera).beta
        // });
        // keysBeta.push({
        //     frame: 50,
        //     value: toBeta
        // });

        // animCamAlpha.setKeys(keysAlpha);
        // animCamBeta.setKeys(keysBeta);

        // this.scene.activeCamera.animations.push(animCamAlpha);
        // this.scene.activeCamera.animations.push(animCamBeta);
        // this.scene.beginAnimation(this.scene.activeCamera, 0, 100);
    };

    doRender(): void {
        // Run the render loop.
        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

        // The canvas/window resize event handler.
        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }

    async updateScene() {
        //Contains some promise chaining code to prevent multiple inits running asyncronosly
        let configuratorScene = this;
        if(this.updateScenePromise === null) {
            this.updateScenePromise = this.executeUpdateScene();
            await this.updateScenePromise;
        }
        else {
            let newUpdatePromise = this.updateScenePromise.then(async function(){await configuratorScene.executeUpdateScene()});
            this.updateScenePromise = newUpdatePromise;
            await this.updateScenePromise;
        }

        //Can be used to export a scene to a GLB file for AR
        //GLTF2Export.GLBAsync(this.scene, "barn.glb").then((glb) => {
        //    glb.downloadFiles();
        //});
    }

    private async executeUpdateScene(){
        await this.shedMeshComposer.UpdateShed(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, (this.sceneManager.shadowGenerator as ShadowGenerator));
        this.sceneManager.UpdateCamera(this.shedMeshComposer);
    }

    async getScreenshotImages() {
        let tempSceneManager = this.sceneManager;
        let tempEngine = this.engine;
        let tempScene = this.scene;
        return tempSceneManager.CreateSceneRenderFiles(this.shedMeshComposer, tempScene, tempEngine);
    }

    async initShed(shedType: string, length: number, width: string, height: number, smallWallHeight: number, wallType: string, updateScene: boolean = true) {
        this.shedMeshComposer.ShedType = ShedType[shedType as keyof typeof ShedType];
        this.shedMeshComposer.Length = length;
        this.shedMeshComposer.Width = Width[width as keyof typeof Width];
        this.shedMeshComposer.Height = height;
        this.shedMeshComposer.SmallWallHeight = smallWallHeight;
        this.shedMeshComposer.WallType = WallType[wallType.split("_")[0]];
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setShedType(value: string, updateScene: boolean = true) {
        this.shedMeshComposer.ShedType = ShedType[value as keyof typeof ShedType];
        if (updateScene) {
            await this.updateScene();
        }
    }

    getShedType(): string {
        return ShedType[this.shedMeshComposer.ShedType]
    }

    async setShedWidth(value: string, updateScene: boolean = true) {
        this.shedMeshComposer.Width = Width[value as keyof typeof Width];
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setShedLength(value: number, updateScene: boolean = true) {
        this.shedMeshComposer.Length = value;
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setShedHeight(value: number, updateScene: boolean = true) {
        this.shedMeshComposer.Height = value;
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setSmallWallHeight(value: number, updateScene: boolean = true) {
        this.shedMeshComposer.SmallWallHeight = value;
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setWallType(value: string, updateScene: boolean = true) {
        value = value.split("_")[0];
        this.shedMeshComposer.WallType = WallType[value as keyof typeof WallType];
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setSmallWallType(value: string, updateScene: boolean = true) {
        value = value.split("_")[0];
        this.shedMeshComposer.SmallWallType = SmallWallType[value as keyof typeof SmallWallType];
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setRoofType(value: string, updateScene: boolean = true) {
        value = value.split("_")[0];
        this.shedMeshComposer.RoofType = RoofType[value as keyof typeof RoofType];
        if (updateScene) {
            await this.updateScene();
        }
    }

    async setGutter(value:string, updateScene: boolean = true) {
        this.shedMeshComposer.GutterOption = GutterOption[value as keyof typeof GutterOption];
        if (updateScene) {
            await this.updateScene();
        }
    }

    async bigAmountOfFacadeElements(): Promise<Array<FacadeElement>> {
        let list = new Array<FacadeElement>();
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Left;
        facadeElement.PositioningDirection = PositioningDirection.Left;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowTurn_101x112;
        facadeElement.SetHighLightType(HighLightType.Error, this.shedMeshComposer.facadeElementBuilder);
        var facadeElementId = await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Right;
        facadeElement.PositioningDirection = PositioningDirection.Left;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowTurn_101x135;
        facadeElement.SetHighLightType(HighLightType.Selected, this.shedMeshComposer.facadeElementBuilder);
        this.shedMeshComposer.facadeElementBuilder.UpdateFacadeElement(facadeElementId, this.shedMeshSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Front;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowStatic_301x112;
        facadeElement.SetHighLightType(HighLightType.Selected, this.shedMeshComposer.facadeElementBuilder);
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Front;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowStatic_201x112;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Left;
        facadeElement.PlaceableMeshType = PlaceableMeshType.OverHeadDoorWindowedHeadWalls_300x275;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Left;
        facadeElement.PlaceableMeshType = PlaceableMeshType.SlidingDoorHeadWalls_150x225;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Left;
        facadeElement.PlaceableMeshType = PlaceableMeshType.DoorRegular_103x216;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Right;
        facadeElement.PlaceableMeshType = PlaceableMeshType.DoorRegularAlternative_100x2125;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Right;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowStatic_201x135;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Right;
        facadeElement.PlaceableMeshType = PlaceableMeshType.DoorEntry_203x216;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Middle;
        facadeElement.PositioningDirection = PositioningDirection.Right;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowStatic_301x135;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Left;
        facadeElement.PositioningDirection = PositioningDirection.Back;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowStatic_401x135;
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        var facadeElement = new FacadeElement();
        facadeElement.OutlineDirection = OutlineDirection.Right;
        facadeElement.PositioningDirection = PositioningDirection.Back;
        facadeElement.PlaceableMeshType = PlaceableMeshType.WindowStatic_401x112;
        facadeElement.SetHighLightType(HighLightType.Error, this.shedMeshComposer.facadeElementBuilder);
        await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement);
        list.push(facadeElement);
        return list;
    }

    performanceProfilers() {
        // Instrumentation
        var instrumentation = new EngineInstrumentation(this.engine);
        instrumentation.captureGPUFrameTime = true;
        instrumentation.captureShaderCompilationTime = true;

        var sceneInstrumentation = new SceneInstrumentation(this.scene);
        sceneInstrumentation.captureActiveMeshesEvaluationTime = true;
        sceneInstrumentation.captureRenderTargetsRenderTime = true;
        //sceneInstrumentation.drawCallsCounter
        sceneInstrumentation.captureCameraRenderTime = true;
        sceneInstrumentation.captureAnimationsTime = true;
        sceneInstrumentation.capturePhysicsTime = true;
        sceneInstrumentation.captureParticlesRenderTime = true;
        sceneInstrumentation.captureSpritesRenderTime = true;

        // GUI
        var advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        var stackPanel = new StackPanel();
        stackPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        stackPanel.isVertical = true;
        advancedTexture.addControl(stackPanel);

        var text1 = new TextBlock();
        text1.text = "";
        text1.color = "black";
        text1.fontSize = 16;
        text1.height = "30px";
        stackPanel.addControl(text1);

        var text2 = new TextBlock();
        text2.text = "";
        text2.color = "black";
        text2.fontSize = 16;
        text2.height = "30px";
        stackPanel.addControl(text2);

        var text3 = new TextBlock();
        text3.text = "";
        text3.color = "black";
        text3.fontSize = 16;
        text3.height = "30px";
        stackPanel.addControl(text3);

        var text4 = new TextBlock();
        text4.text = "";
        text4.color = "black";
        text4.fontSize = 16;
        text4.height = "30px";
        stackPanel.addControl(text4);

        var text5 = new TextBlock();
        text5.text = "";
        text5.color = "black";
        text5.fontSize = 16;
        text5.height = "30px";
        stackPanel.addControl(text5);

        var text6 = new TextBlock();
        text6.text = "";
        text6.color = "black";
        text6.fontSize = 16;
        text6.height = "30px";
        stackPanel.addControl(text6);

        var text7 = new TextBlock();
        text7.text = "";
        text7.color = "black";
        text7.fontSize = 16;
        text7.height = "30px";
        stackPanel.addControl(text7);

        var text8 = new TextBlock();
        text8.text = "";
        text8.color = "black";
        text8.fontSize = 16;
        text8.height = "30px";
        stackPanel.addControl(text8);

        var text9 = new TextBlock();
        text9.text = "";
        text9.color = "black";
        text9.fontSize = 16;
        text9.height = "30px";
        stackPanel.addControl(text9);

        this.scene.registerBeforeRender(function () {
            text1.text = "current frame time (GPU): " + (instrumentation.gpuFrameTimeCounter.current * 0.000001).toFixed(2) + "ms";
            text2.text = "compiler shaders count: " + instrumentation.shaderCompilationTimeCounter.count;
            text3.text = "ActiveMeshesEvaluationTime: " + sceneInstrumentation.activeMeshesEvaluationTimeCounter.current.toFixed(2) + "ms";
            text4.text = "RenderTargetsRenderTime: " + sceneInstrumentation.renderTargetsRenderTimeCounter.current.toFixed(2) + "ms";
            text5.text = "CameraRenderTime: " + sceneInstrumentation.cameraRenderTimeCounter.current.toFixed(2) + "ms";
            text6.text = "AnimationsTime: " + sceneInstrumentation.animationsTimeCounter.current.toFixed(2) + "ms";
            text7.text = "PhysicsTime: " + sceneInstrumentation.physicsTimeCounter.current.toFixed(2) + "ms";
            text8.text = "ParticlesRenderTime: " + sceneInstrumentation.particlesRenderTimeCounter.current.toFixed(2) + "ms";
            text9.text = "SpritesRenderTime: " + sceneInstrumentation.spritesRenderTimeCounter.current.toFixed(2) + "ms";
        });
    }
    
    rotateCameraToDirection(direction: PositioningDirection) {
        switch (direction) {
            case PositioningDirection.Front:
                this.ArcAnimation(Math.PI * 1.5, Math.PI / 2);
                break;
            case PositioningDirection.Left:
                this.ArcAnimation(Math.PI, Math.PI / 2);
                break;
            case PositioningDirection.Right:
                this.ArcAnimation(0, Math.PI / 2);
                break;
            case PositioningDirection.Back:
                this.ArcAnimation(Math.PI * 0.5, Math.PI / 2);
                break;
        }
    }
    
    setWallColor(color: string) {
        this.shedMeshComposer.SetWallColor(UtilityFunctions.hexToColor3(color), this.shedMaterialSupplier);
    }

    setRoofColor(color: string) {
        this.shedMeshComposer.SetRoofColor(UtilityFunctions.hexToColor3(color), this.shedMaterialSupplier);
    }

    async setSmallWallColor(color: string, smallWallMaterial:string, updateScene:boolean = true) {
        this.shedMeshComposer.SmallWallMaterial = SmallWallMaterial[smallWallMaterial as keyof typeof SmallWallMaterial];
        this.shedMeshComposer.SetSmallWallColor(UtilityFunctions.hexToColor3(color), this.shedMaterialSupplier);
        if(updateScene) {
            await this.updateScene();
        }
    }

    async addFacadeElement(placableMeshType: string, positioningDirection: string, outlineDirection: string, color:string, updateScene: boolean = true) {
        var facadeElement = new FacadeElement();

        facadeElement.OutlineDirection = OutlineDirection[outlineDirection as keyof typeof OutlineDirection];
        facadeElement.PositioningDirection = PositioningDirection[positioningDirection as keyof typeof PositioningDirection];
        facadeElement.PlaceableMeshType = PlaceableMeshType[placableMeshType as keyof typeof PlaceableMeshType];
        facadeElement.Color = UtilityFunctions.hexToColor3(color);
        //facadeElement.SetHighLightType(HighLightType.Selected, this.shedMeshComposer.facadeElementBuilder);
        var returnValue = await this.shedMeshComposer.facadeElementBuilder.AddFacadeElement(this.shedMeshSupplier, this.shedMaterialSupplier, this.scene, facadeElement, updateScene);
        var mesh = await facadeElement.GetMesh(this.shedMeshSupplier, this.scene);
        mesh.isVisible = true;

        this.behaviorClick(mesh);
        this.behaviourDrag(mesh,[]);
        //this.createNewElement(this.scene,mesh);
       
        return returnValue;
    }

    async removeFacadeElement(id: number) {
        this.shedMeshComposer.facadeElementBuilder.DeleteFacadeElement(id);
        await this.updateScene();
    }

    initDefaultShed() {
        this.shedMeshComposer.Height = 3;
        this.shedMeshComposer.Width = Width.Size8;
        this.shedMeshComposer.Length = 35;
        this.shedMeshComposer.ShedType = ShedType.Warehouse;
        this.shedMeshComposer.SmallWallHeight = 0;
        this.shedMeshComposer.GutterOption = GutterOption.OneSide;
        this.shedMeshComposer.WallType = WallType.LarssenSheetPiling;
        this.shedMeshComposer.SetWallColor(new Color3(0.2188, 0.2431, 0.2578), this.shedMaterialSupplier);
        this.shedMeshComposer.RoofType = RoofType.CorrugatedIron;
        this.shedMeshComposer.SetRoofColor(new Color3(0.2188, 0.2431, 0.2578), this.shedMaterialSupplier);
        this.shedMeshComposer.SmallWallType = SmallWallType.None;
    }
}