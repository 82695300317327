import { HighlightLayer, AbstractMesh, Scene, Mesh, Color3, ActionManager, ExecuteCodeAction, PointerDragBehavior, Vector3, Material } from '@babylonjs/core';
import { HighLightType } from './Enums/highlightType';
import { OutlineDirection } from "./Enums/outlineDirection";
import { PlaceableMeshType } from "./Enums/placeableMeshes";
import { PositioningDirection } from "./Enums/positioningDirection";
import { ShedType } from "./Enums/shedType";
import { WallType } from "./Enums/wallType";
import FacadeElement from "./facadeElement";
import ShedMaterialSupplier from "./shedMaterialSupplier";
import ShedMeshComposer from './shedMeshComposer';
import ShedMeshSupplier from "./shedMeshSupplier";
import UtilityFunctions from './utilityFunctions';

export default class FacadeElementBuilder {
    facadeElements:Array<FacadeElement> = new Array<FacadeElement>();
    highLightLayer:HighlightLayer;
    selectedMeshName:string = null;
    private facadeCounter = 0;
    private windowHeight = 1;
    private shedMeshComposer:ShedMeshComposer;
    private selectedMesh: AbstractMesh;
    facadeElementMeshes:Array<AbstractMesh> = new Array<AbstractMesh>();

    constructor(shedMeshComposer:ShedMeshComposer, highLightLayer:HighlightLayer) {
        this.shedMeshComposer = shedMeshComposer;
        this.highLightLayer = highLightLayer;
    }

    async AddFacadeElement(shedMeshSupplier:ShedMeshSupplier, shedMaterialSupplier:ShedMaterialSupplier, scene:Scene, facadeElement:FacadeElement, updateFacadeElements:boolean = false) : Promise<number> {
        facadeElement.id = this.facadeCounter;
        this.facadeCounter++;
        this.facadeElements.push(facadeElement);

        if(updateFacadeElements){
            await this.shedMeshComposer.facadeElementBuilder.BuildFacadeElement(shedMeshSupplier, shedMaterialSupplier, scene);
        }

        return facadeElement.id;
    }

    UpdateFacadeElement(id:number, shedMeshSupplier:ShedMeshSupplier, scene:Scene, facadeElement:FacadeElement) : boolean {
        var elements = this.facadeElements.filter((value) => {
            return value.id == id;
        });
        if(elements.length === 0) {
            return false;
        }

        var facadeElementCopy = this.facadeElements;
        const index = facadeElementCopy.indexOf(elements[0], 0);
        if (index > -1) {
            facadeElementCopy[index] = facadeElement;
            var facadeElementFits = this.DoFacadeElementsFit(facadeElementCopy, facadeElement.PositioningDirection, shedMeshSupplier, scene);
            if(facadeElementFits){
                this.facadeElements[index] = facadeElement;
                return true;
            }
            return false;
        }
        return false;
    }

    DeleteFacadeElement(id:number) : boolean {
        var elements = this.facadeElements.filter((value) => {
            return value.id == id;
        });
        if(elements.length === 0) {
            return false;
        }
        const index = this.facadeElements.indexOf(elements[0], 0);
        if (index > -1) {
            this.facadeElements.splice(index, 1);
            return true;
        }
        return false;
    }

    GetFacadeElement(id:number) : FacadeElement{
        var elements = this.facadeElements.filter((value) => {
            return value.id == id;
        });
        if(elements.length === 0){
            return null;
        }
        return elements[0];
    }

    async DoesFacadeElementFit(shedMeshSupplier:ShedMeshSupplier, scene:Scene, facadeElement:FacadeElement) : Promise<boolean> {
        if(this.shedMeshComposer.ShedType === ShedType.FieldShed && facadeElement.PositioningDirection === PositioningDirection.Right){
            return false;
        }
        if((await facadeElement.GetMesh(shedMeshSupplier, scene)).getBoundingInfo().boundingBox.extendSize.y*2 > this.shedMeshComposer.Height){
            return false;
        }

        var filteredFacadeElements = this.facadeElements.filter((value) => {return value.PositioningDirection == facadeElement.PositioningDirection });
        filteredFacadeElements.push(facadeElement);

        return await this.DoFacadeElementsFit(filteredFacadeElements, facadeElement.PositioningDirection, shedMeshSupplier, scene);
    }

    async DoFacadeElementsFit(facadeElements:Array<FacadeElement>, positionDirection:PositioningDirection, shedMeshSupplier:ShedMeshSupplier, scene:Scene) : Promise<boolean>{
        var leftFacadeElement = facadeElements.filter((value) => {return value.OutlineDirection == OutlineDirection.Left});
        var middleFacadeElement = facadeElements.filter((value) => {return value.OutlineDirection == OutlineDirection.Middle});
        var rightFacadeElement = facadeElements.filter((value) => {return value.OutlineDirection == OutlineDirection.Right});

        var maxSize = positionDirection == PositioningDirection.Front || positionDirection == PositioningDirection.Back ? this.shedMeshComposer.Width : this.shedMeshComposer.Length;
        
        var leftSize = 0;
        for(const value of leftFacadeElement) {
            leftSize += value.GetMargin() * 2;
            leftSize += (await value.GetMesh(shedMeshSupplier, scene)).getBoundingInfo().boundingBox.extendSize.x * 2;
        }
        var middleSize = 0;
        for(const value of middleFacadeElement) {
            middleSize += value.GetMargin() * 2;
            middleSize += (await value.GetMesh(shedMeshSupplier, scene)).getBoundingInfo().boundingBox.extendSize.x * 2;
        }
        var rightSize = 0;
        for(const value of rightFacadeElement) { 
            rightSize += value.GetMargin() * 2;
            rightSize += (await value.GetMesh(shedMeshSupplier, scene)).getBoundingInfo().boundingBox.extendSize.x * 2;
        }
        if(middleSize > maxSize){
            return false;
        }
        if(middleSize !== 0){
            var roomLeftAndRight = (maxSize - middleSize)/2;
            if(roomLeftAndRight < leftSize || roomLeftAndRight < rightSize){
                return false;
            }
        }
        else{
            if(maxSize < leftSize + rightSize){
                return false;
            }
        }
        return true;
    }

    async BuildFacadeElement(shedMeshSupplier:ShedMeshSupplier, shedMaterialSupplier:ShedMaterialSupplier, scene:Scene){
        this.facadeElementMeshes.forEach(mesh => {
            mesh.dispose();
        });
        this.facadeElementMeshes = new Array<AbstractMesh>();

        var frontFacadeElement = this.facadeElements.filter((value) => { return value.PositioningDirection == PositioningDirection.Front });
        var leftFacadeElement = this.facadeElements.filter((value) => { return value.PositioningDirection == PositioningDirection.Left });
        var rightFacadeElement = this.facadeElements.filter((value) => { return value.PositioningDirection == PositioningDirection.Right });
        var backFacadeElement = this.facadeElements.filter((value) => { return value.PositioningDirection == PositioningDirection.Back });
        
        await this.BuildDirectionalFacadeElement(shedMeshSupplier, shedMaterialSupplier, scene, frontFacadeElement, PositioningDirection.Front);
        await this.BuildDirectionalFacadeElement(shedMeshSupplier, shedMaterialSupplier, scene, leftFacadeElement, PositioningDirection.Left);
        await this.BuildDirectionalFacadeElement(shedMeshSupplier, shedMaterialSupplier, scene, backFacadeElement, PositioningDirection.Back);
        if(this.shedMeshComposer.ShedType !== ShedType.FieldShed){
            await this.BuildDirectionalFacadeElement(shedMeshSupplier, shedMaterialSupplier, scene, rightFacadeElement, PositioningDirection.Right);
        }
    }

      
 applyOutline(mesh: Mesh) {
    var outlineColor = new Color3(0.14, 0.49, 0.7);
    var outlineWidth = 0.02;

    if (this.selectedMesh) {
        this.selectedMesh.renderOutline = false;
        this.selectedMesh.material.alpha = 1;
      }
  
      mesh.renderOutline = true;
      mesh.outlineColor = outlineColor;
      mesh.outlineWidth = outlineWidth;
      //mesh.outlineCorner = this.outlineCorner; 
      mesh.material.alpha = 0.5;
      this.selectedMesh = mesh;
  }

    behaviorClick(mesh: Mesh, scene: Scene) {
        mesh.actionManager = new ActionManager(scene);
        mesh.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnPickTrigger, (evt) => {
              this.applyOutline(mesh);
            })
        );
    }

    behaviourDrag(object:Mesh, stoppers, moveMode = 0) {
        var pointerDragBehavior = new PointerDragBehavior({ dragPlaneNormal: new Vector3(0, 0, 1) });
        pointerDragBehavior.moveAttached = false;
    
        pointerDragBehavior.onDragStartObservable.add(() => { moveMode });
        pointerDragBehavior.onDragObservable.add((event) => {
            const oldAbs = object.getAbsolutePosition().clone();

            if (moveMode == 0) {
                object.position.x += event.delta.x;
            } else if (moveMode == 1) {
                object.position.x += event.delta.x;
                object.position.y += event.delta.y;
            }

            const stopDrag = (stoppers || []).every(stopper => {
                return (object.intersectsMesh(stopper, true))
            });
            if(stopDrag)
            {
                object.setAbsolutePosition(oldAbs);
                object.computeWorldMatrix(true);
            }
        });
    
        pointerDragBehavior.onDragStartObservable.add(() => {
            this.applyOutline(object);
        });
    
        pointerDragBehavior.onDragEndObservable.add(() => {
            this.restoreMaterial();
        });

        object.addBehavior(pointerDragBehavior);
    }

    // behaviourDrag(object, stoppers, moveMode = 0) {
    //     var pointerDragBehavior = new PointerDragBehavior({ dragPlaneNormal: new Vector3(0, 0, 1) });
    //     pointerDragBehavior.useObjectOrientationForDragging = false;
    //     pointerDragBehavior.updateDragPlane = false;
    //     pointerDragBehavior.validateDrag = (newPos) => {
    //         const cloneObject = object.clone();
    //         const oldAbs = object.getAbsolutePosition().clone();
    //         object.setAbsolutePosition(newPos);
    //         object.computeWorldMatrix(true);
    //         const stopDrag = (stoppers || []).every(stopper => {
    //             return (object.intersectsMesh(stopper, true))
    //         });
    //         return !stopDrag;
    //     }
    //     object.addBehavior(pointerDragBehavior);

    //     pointerDragBehavior.onDragStartObservable.add(function () {
    //         moveMode;
    //         this.applyOutline(object);
    //     });

    //     pointerDragBehavior.onDragObservable.add((event) => {
    //         if (moveMode == 0) {
    //             object.position.x += event.delta.x;
    //         } else if (moveMode == 1) {
    //             object.position.x += event.delta.x;
    //             object.position.y += event.delta.y;
    //         }
    //     });
    
    //     pointerDragBehavior.onDragEndObservable.add(function () {
    //         this.restoreMaterial();
    //     });
    // }

    restoreMaterial() {
        if (this.selectedMesh) {
            this.selectedMesh.material.alpha = 1;
          }
    }

    async BuildDirectionalFacadeElement(shedMeshSupplier:ShedMeshSupplier, shedMaterialSupplier:ShedMaterialSupplier, scene:Scene, facadeElements:FacadeElement[], positionDirection:PositioningDirection){
        var maxSize = positionDirection == PositioningDirection.Front || positionDirection == PositioningDirection.Back ? this.shedMeshComposer.Width : this.shedMeshComposer.Length; 
        var distanceFromCenter = positionDirection == PositioningDirection.Front || positionDirection == PositioningDirection.Back ? this.shedMeshComposer.Length /2 : this.shedMeshComposer.Width/ 2; 

        var leftFacadeElement = facadeElements.filter((value) => { return value.OutlineDirection == OutlineDirection.Left });
        var middleFacadeElement = facadeElements.filter((value) => { return value.OutlineDirection == OutlineDirection.Middle });
        var rightFacadeElement = facadeElements.filter((value) => { return value.OutlineDirection == OutlineDirection.Right });

        var leftOffset = 0;
        for(const value of leftFacadeElement) {
            var mesh = await value.GetMesh(shedMeshSupplier, scene);
            var leftDirection = value.GetDirectionVector().cross(Vector3.Down());
            var centerPosition = this.shedMeshComposer.ShedPosition.add(value.GetDirectionVector().multiplyByFloats(distanceFromCenter,distanceFromCenter,distanceFromCenter));
            var cornerPosition = centerPosition.add(leftDirection.multiplyByFloats(maxSize/2,maxSize/2,maxSize/2));
            var meshWidth = mesh.getBoundingInfo().boundingBox.extendSizeWorld.x * 2;
            var moveBackAmount = leftOffset + value.GetMargin() + meshWidth / 2;
            var finalPosition = cornerPosition.add(leftDirection.multiply(new Vector3(-1,-1,-1)).multiplyByFloats(moveBackAmount,moveBackAmount,moveBackAmount));
            
            if(PlaceableMeshType[value.PlaceableMeshType].startsWith("Window")){
                finalPosition = finalPosition.add(new Vector3(0,this.shedMeshComposer.SmallWallHeight < this.windowHeight ? this.windowHeight : this.shedMeshComposer.SmallWallHeight,0))
            }
            let bodyMeshName = this.InstantiateFacadeElement(shedMaterialSupplier, value.HighLightType, this.shedMeshComposer.WallType, mesh, finalPosition, value.GetDirectionVector(), value.Color,scene);
            value.BodyMeshName = bodyMeshName;
            
            leftOffset += value.GetMargin() * 2;
            leftOffset += meshWidth;
        }


        var middleSize = 0;
        for(const value of middleFacadeElement){
            middleSize += value.GetMargin() * 2;
            middleSize += (await value.GetMesh(shedMeshSupplier, scene)).getBoundingInfo().boundingBox.extendSize.x * 2;
        }

        var middleOffset = 0;
        for(const value of middleFacadeElement) {
            var mesh = await value.GetMesh(shedMeshSupplier, scene);
            var leftDirection = value.GetDirectionVector().cross(Vector3.Down());
            var centerPosition = this.shedMeshComposer.ShedPosition.add(value.GetDirectionVector().multiplyByFloats(distanceFromCenter,distanceFromCenter,distanceFromCenter));
            var cornerPosition = centerPosition.add(leftDirection.multiplyByFloats(middleSize/2,middleSize/2,middleSize/2));
            var meshWidth = mesh.getBoundingInfo().boundingBox.extendSizeWorld.x * 2;
            var moveBackAmount = middleOffset + value.GetMargin() + meshWidth / 2;
            var finalPosition = cornerPosition.add(leftDirection.multiply(new Vector3(-1,-1,-1)).multiplyByFloats(moveBackAmount,moveBackAmount,moveBackAmount));
            
            if(PlaceableMeshType[value.PlaceableMeshType].startsWith("Window")){
                finalPosition = finalPosition.add(new Vector3(0,this.shedMeshComposer.SmallWallHeight < this.windowHeight ? this.windowHeight : this.shedMeshComposer.SmallWallHeight,0))
            }
            let bodyMeshName = this.InstantiateFacadeElement(shedMaterialSupplier, value.HighLightType, this.shedMeshComposer.WallType, mesh, finalPosition, value.GetDirectionVector(), value.Color,scene);
            value.BodyMeshName = bodyMeshName;

            middleOffset += value.GetMargin() * 2;
            middleOffset += meshWidth;
        }
        
        var rightOffset = 0;
        for(const value of rightFacadeElement) { 
            var mesh = await value.GetMesh(shedMeshSupplier, scene);
            var rightDirection = value.GetDirectionVector().cross(Vector3.Up());
            var centerPosition = this.shedMeshComposer.ShedPosition.add(value.GetDirectionVector().multiplyByFloats(distanceFromCenter,distanceFromCenter,distanceFromCenter));
            var cornerPosition = centerPosition.add(rightDirection.multiplyByFloats(maxSize/2,maxSize/2,maxSize/2));
            var meshWidth = mesh.getBoundingInfo().boundingBox.extendSizeWorld.x * 2;
            var moveBackAmount = rightOffset + value.GetMargin() + meshWidth / 2;
            var finalPosition = cornerPosition.add(rightDirection.multiply(new Vector3(-1,-1,-1)).multiplyByFloats(moveBackAmount,moveBackAmount,moveBackAmount));
            
            if(PlaceableMeshType[value.PlaceableMeshType].startsWith("Window")){
                finalPosition = finalPosition.add(new Vector3(0, this.shedMeshComposer.SmallWallHeight < this.windowHeight ? this.windowHeight : this.shedMeshComposer.SmallWallHeight,0))
            }
            let bodyMeshName = this.InstantiateFacadeElement(shedMaterialSupplier, value.HighLightType, this.shedMeshComposer.WallType, mesh, finalPosition, value.GetDirectionVector(), value.Color,scene);
            value.BodyMeshName = bodyMeshName;

            rightOffset += value.GetMargin() * 2;
            rightOffset += meshWidth;
        }

        // this.facadeElementMeshes.forEach(mesh => {
        //     if (mesh.name.includes("Frame")) {
        //         this.behaviorClick(mesh as Mesh,scene);
        //         this.behaviourDrag(mesh as Mesh,this.facadeElementMeshes as Mesh[] )
        //     }
        // });
    }

    InstantiateFacadeElement(materialSupplier:ShedMaterialSupplier, highLightType:HighLightType, wallType:WallType, mesh:Mesh, position:Vector3, lookDirection:Vector3, color:Color3,scene:Scene) : string{
        let frameMeshName = "";
        if(this.GetFacadeElementMaterial(materialSupplier, mesh.name, wallType, color) !== null){
            mesh.material = this.GetFacadeElementMaterial(materialSupplier, mesh.name, wallType, color);
        }

        var meshInstance = mesh.clone(mesh.name + UtilityFunctions.uuidv4(), null, true, true);
        meshInstance.isVisible = true;
        
        if(mesh.name.includes("Frame") && !mesh.name.endsWith("Window_Frame")){
            frameMeshName = meshInstance.name;
            //this.SetHighLightColor(highLightType, (meshInstance as AbstractMesh));
        }

        this.behaviorClick(meshInstance as Mesh,scene);
        if (mesh.name.startsWith("W")) {
            this.behaviourDrag(meshInstance as Mesh,this.facadeElementMeshes as Mesh[],1)
        }
        else
        {
            this.behaviourDrag(meshInstance as Mesh,this.facadeElementMeshes as Mesh[] )
        }

        this.facadeElementMeshes.push(meshInstance);

        let childMeshes = mesh.getChildMeshes(true);
        childMeshes.forEach((value) => {
            if(this.GetFacadeElementMaterial(materialSupplier, value.name, wallType, color) !== null){
                value.material = this.GetFacadeElementMaterial(materialSupplier, value.name, wallType, color);
            }
            
            let element = (value as Mesh).clone(value.name + UtilityFunctions.uuidv4(), meshInstance);
            element.isVisible = true;
            if(value.name.includes("Frame") && !value.name.endsWith("Window_Frame")){
                frameMeshName = element.name;
                //this.SetHighLightColor(highLightType, (element as AbstractMesh));
            }
            this.facadeElementMeshes.push(element);
        });

        meshInstance.position = position;
        meshInstance.setDirection(lookDirection);
        return frameMeshName;
    }

    private SetHighLightColor(highLightType:HighLightType, mesh:AbstractMesh){
        switch(highLightType){
            case HighLightType.None:
                break;
            case HighLightType.Error:
                this.highLightLayer.addMesh((mesh as Mesh), Color3.Red());
                break;
            case HighLightType.Selected:
                this.highLightLayer.addMesh((mesh as Mesh), Color3.Green());
                this.selectedMeshName = mesh.name;
                break;
        }
    }

    GetFacadeElementMaterial(materialSupplier:ShedMaterialSupplier, name:string, wallType:WallType, color:Color3) : Material {
        if(name.endsWith("Glass")) {
            return materialSupplier.GetGlassMaterial();
        }
        if(name.endsWith("Frame") && !name.endsWith("Window_Frame")) {
            return materialSupplier.GetWallWithoutBumpMaterial(wallType);
        }
        if(name.startsWith("D1") && name.endsWith("Body")) {
            return materialSupplier.GetWallMaterialColoured(WallType.Clapboard, color, false);
        }
        if(name.startsWith("D2") && name.endsWith("Body")) {
            return materialSupplier.GetWallMaterialColoured(WallType.Clapboard, color, false);
        }
        if(name.startsWith("D3") && name.endsWith("Body")) {
            return materialSupplier.GetWallMaterialColoured(WallType.Clapboard, color, false);
        }
        if(name.startsWith("D4") && name.endsWith("Body")) {
            return materialSupplier.GetWallMaterialColoured(WallType.LarssenSheetPiling, color, true);
        }
        if(name.startsWith("D5") && name.endsWith("Body")) {
            return materialSupplier.GetWallMaterialColoured(WallType.SandwichPanel, color, true);
        }
        if(name.startsWith("D7") && name.endsWith("Body")) {
            return materialSupplier.GetWallMaterialColoured(WallType.SandwichPanel, color, true);
        }
        if(name.startsWith("W") && name.endsWith("Frame")){
            return materialSupplier.GetWallMaterialColoured(WallType.Clapboard, color, true);
        }
        return null;
    }
}