import { Width } from "./Enums/width";
import { ShedType } from "./Enums/shedType";
import ShedMaterialSupplier from "./shedMaterialSupplier";
import { WallType } from "./Enums/wallType";
import { SmallWallType } from "./Enums/smallWallType";
import { RoofType } from "./Enums/roofType";
import MeshCutter from "./meshCutter";
import { TreeMaterial } from "./Enums/treeMaterial";
import { PlaceableMeshType } from "./Enums/placeableMeshes";
import MeshComponent from "./meshComponent";
import SceneManager from "./sceneManager";
import { SmallWallMaterial } from "./Enums/smallWallMaterial";
import { Scene, Vector3, AbstractMesh, Mesh, StandardMaterial, Color3, Material, SceneLoader } from "@babylonjs/core";
import "@babylonjs/loaders/OBJ/objFileLoader"; // Do not remove, this is required for the SceneLoader to work with .obj files


export default class ShedMeshSupplier {
    private spant:string;
    private spantBottom:string;

    private gutterRootUrl = "Meshes/Details/";
    private wallFileName = "WA";
    private meshesRootUrl = "Meshes/";
    private roofsRootUrl = "Meshes/Roofs/";
    private wallRootUrl = "Meshes/Walls/";
    private detailsRootUrl = "Meshes/Details/";
    private spantFileName = "I_Profile";
    private spantBottomFileName = "I_Profile_Base";
    private pillarFileName = "CORNER_PILLAR";
    private smallWallDetailFileName = "Wall_Detail";
    private smallWallDetailCornerFileName = "Wall_Detail_corner";
    private roofFrontPanelFileName = "Roof_Detail_Side";
    private roofTopFileName = "Roof_nok";
    private gutterFileName = "Gutter";
    private gutterPipeFileName = "Gutter_pipe";
    private facadeElementRootUrl = "Meshes/Windows Doors/";
    private huismanLogo = "Panel_logo";
    private huismanLogoPanel = "Panel_panel";
    private grassModel = "grass";
    private treeCircular = "Tree_circular";
    private tree = "Tree";
    private bush = "Bush";

    private materialSupplier:ShedMaterialSupplier;
    private sceneManager:SceneManager;

    constructor(materialSupplier:ShedMaterialSupplier, sceneManager:SceneManager) {
        this.materialSupplier = materialSupplier;
        this.sceneManager = sceneManager;
    }

    public async LoadMeshes(scene :Scene) {
        await this.LoadRoofs(scene, RoofType.LarssenSheetPiling, this.roofsRootUrl);
        await this.LoadRoofs(scene, RoofType.CorrugatedIron, this.roofsRootUrl);
        await this.LoadRoofs(scene, RoofType.SandwichPanelCorrugatedIron, this.roofsRootUrl);
        await this.LoadRoofs(scene, RoofType.SandwichPanelTrapezium, this.roofsRootUrl);

        await this.LoadWalls(scene, WallType.LarssenSheetPiling);
        await this.LoadWalls(scene, WallType.SandwichPanel);
        await this.LoadWalls(scene, WallType.Clapboard);

        await this.LoadSmallWall(scene, SmallWallType.Concrete);
        await this.LoadSmallWall(scene, SmallWallType.ConcreteIsolated);
        await this.LoadSmallWall(scene, SmallWallType.DampProof);

        await this.LoadSpant(scene);
        await this.LoadGutters(scene, this.gutterRootUrl);

        await this.LoadPillar(scene);
        
        await this.LoadSmallWallDetail(scene);

        await this.LoadRoofDetails(scene, this.detailsRootUrl);

        await this.LoadDetails(scene);
    }

    public GetSmallWallDetailCorner(scene :Scene){
        return scene.getMeshByName(this.smallWallDetailCornerFileName);
    }

    public GetSmallWallDetail(scene :Scene){
        return scene.getMeshByName(this.smallWallDetailFileName);
    }

    public GetRoofTop(scene :Scene){
        return scene.getMeshByName(this.roofTopFileName);
    }

    public GetPillarMesh(scene :Scene){
        return scene.getMeshByName(this.pillarFileName);
    }

    public GetWallMesh(scene :Scene, positions:Vector3[], wallType:WallType) : AbstractMesh {
        var largeWallMesh =  scene.getMeshByName("W" + this.wallFileName + this.GetWallMeshFileExtensionFromType(wallType) + "_Big") as Mesh;
        var wallMesh = MeshCutter.sliceMesh(largeWallMesh, positions, scene);
        return wallMesh as Mesh;
    }

    public GetRoofMesh(scene :Scene, roofType:RoofType) : AbstractMesh {
        return scene.getMeshByName("R" + this.GetRoofMeshFileExtensionFromType(roofType));
    }
    
    public GetRoofFrontPanel(scene :Scene) : AbstractMesh {
        return scene.getMeshByName(this.roofFrontPanelFileName);
    }
    
    public GetGutterHorizontalMesh(scene :Scene) : AbstractMesh {
        return scene.getMeshByName(this.gutterFileName);
    }
    
    public GetGutterVerticalMiddleMesh(scene :Scene) : AbstractMesh {
        return scene.getMeshByName(this.gutterPipeFileName);
    }
    
    public GetSmallWallMesh(scene :Scene, smallWallType:SmallWallType) : AbstractMesh {
        return scene.getMeshByName("S" + this.wallFileName + this.GetSmallWallMeshFileExtensionFromType(smallWallType)); // planarwall on purpose, since the offset is not required
    }

    public GetSpantMesh(scene :Scene) : AbstractMesh {
        return scene.getMeshByName(this.spant);
    }
    
    public GetSpantBottomMesh(scene :Scene) : AbstractMesh {
        return scene.getMeshByName(this.spantBottom);
    }
    
    public async GetWindow(scene:Scene, placeableMeshType:PlaceableMeshType, hasTurnWindow:boolean) : Promise<AbstractMesh> {
        let windowPrefix = "W";
        switch(placeableMeshType){
            case PlaceableMeshType.WindowTurn_101x112:
            case PlaceableMeshType.WindowTurn_101x135:
            case PlaceableMeshType.WindowStatic_101x112:
            case PlaceableMeshType.WindowStatic_101x135:
                windowPrefix += "1";
                break;
            case PlaceableMeshType.WindowTurn_201x112:
            case PlaceableMeshType.WindowTurn_201x135:
            case PlaceableMeshType.WindowStatic_201x112:
            case PlaceableMeshType.WindowStatic_201x135:
                windowPrefix += "2";
                break;
            case PlaceableMeshType.WindowTurn_301x112:
            case PlaceableMeshType.WindowTurn_301x135:
            case PlaceableMeshType.WindowStatic_301x112:
            case PlaceableMeshType.WindowStatic_301x135:
                windowPrefix += "3";
                break;
            case PlaceableMeshType.WindowTurn_401x112:
            case PlaceableMeshType.WindowTurn_401x135:
            case PlaceableMeshType.WindowStatic_401x112:
            case PlaceableMeshType.WindowStatic_401x135:
                windowPrefix += "4";
                break;
        }
        let turnOrStaticName = "Static";
        if(PlaceableMeshType[placeableMeshType].startsWith("WindowTurn")){
            turnOrStaticName = "Turn";
        }
        let sizeName = PlaceableMeshType[placeableMeshType].replace("Window" + turnOrStaticName + "_", "");
        let componentList:Array<MeshComponent> = [
            new MeshComponent("_Frame", null),
            new MeshComponent("Window_Frame", null),
            new MeshComponent("_Glass", this.materialSupplier.GetGlassMaterial())
        ];
        return await this.GetMeshClump(scene, this.facadeElementRootUrl,`${windowPrefix}_${sizeName}_${turnOrStaticName}`, componentList);
    }

    public async GetDoor(scene:Scene, placeableMeshType:PlaceableMeshType) : Promise<AbstractMesh> {
        let doorPrefix = "D";
        let componentList:Array<MeshComponent> = [
            new MeshComponent("_Frame", null),
            new MeshComponent("_Body", null)
        ];
        let sizeName = "";
        switch(placeableMeshType){
            case PlaceableMeshType.DoorEntry_103x216:
            case PlaceableMeshType.DoorEntry_103x240:
            case PlaceableMeshType.DoorEntry_203x216:
            case PlaceableMeshType.DoorEntry_203x240:
                doorPrefix += "1";
                componentList.push(new MeshComponent("_Glass", this.materialSupplier.GetGlassMaterial()));
                break;
            case PlaceableMeshType.DoorRegular_103x216:
            case PlaceableMeshType.DoorRegular_103x240:
            case PlaceableMeshType.DoorRegular_203x216:
            case PlaceableMeshType.DoorRegular_203x240:
                doorPrefix += "2";
                break;
            case PlaceableMeshType.DoorRegularAlternative_100x2125:
                doorPrefix += "3";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_300x275:
            case PlaceableMeshType.OverHeadDoorWindowedSideWalls_300x275:
                sizeName = "300x300";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_350x350:
                sizeName = "300x300";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_400x375:
                sizeName = "400x400";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_400x425:
                sizeName = "400x400";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedSideWalls_455x275:
                sizeName = "450x275";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedSideWalls_455x375:
            case PlaceableMeshType.OverHeadDoorWindowedSideWalls_455x405:
            case PlaceableMeshType.OverHeadDoorWindowedSideWalls_455x425:
                sizeName = "450x375";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_500x425:
                sizeName = "500x400";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_500x475:
                sizeName = "500x450";
                break;
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_600x450:
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_600x475:
            case PlaceableMeshType.OverHeadDoorWindowedHeadWalls_600x500:
                sizeName = "600x500";
                break;
            case PlaceableMeshType.SlidingDoorHeadWalls_275x275:
            case PlaceableMeshType.SlidingDoorSideWalls_275x275:
                sizeName = "250x275";
                break;
            case PlaceableMeshType.SlidingDoorSideWalls_300x285:
                sizeName = "300x300";
                break;
            case PlaceableMeshType.SlidingDoubleDoorSideWalls_500x420:
                sizeName = "500x425";
                break;
            case PlaceableMeshType.SlidingDoubleDoorSideWalls_500x440:
                sizeName = "500x450";
                break;
            case PlaceableMeshType.SlidingDoubleDoorHeadWalls_500x435:
                sizeName = "500x425";
                break;
            case PlaceableMeshType.SlidingDoubleDoorHeadWalls_500x455:
                sizeName = "500x450";
                break;
            case PlaceableMeshType.SlidingDoubleDoorHeadWalls_600x450:
            case PlaceableMeshType.SlidingDoubleDoorHeadWalls_600x475:
                sizeName = "600x500";
            break;
        }
        if(PlaceableMeshType[placeableMeshType].startsWith("OverHeadDoorWindowed")){
            doorPrefix += "5";
            componentList.push(new MeshComponent("_Glass", this.materialSupplier.GetGlassMaterial()));
            componentList.push(new MeshComponent("_Window_Frame", null));
        }
        if(PlaceableMeshType[placeableMeshType].startsWith("SlidingDoor")) {
            componentList.push(new MeshComponent("_Door_Frame", this.materialSupplier.GetPlasticMaterial()));
            doorPrefix += "7";
        }
        if(PlaceableMeshType[placeableMeshType].startsWith("SlidingDoubleDoor")){
            componentList = [
                new MeshComponent("_Double_Frame", null),
                new MeshComponent("_Double_Body", this.materialSupplier.GetPlasticMaterial()),
                new MeshComponent("_Double_Door_Frame", null)
            ];
            doorPrefix += "7";
        }
        if(sizeName === ""){
            sizeName = PlaceableMeshType[placeableMeshType].split("_")[1];
        }
        return await this.GetMeshClump(scene, this.facadeElementRootUrl,`${doorPrefix}_${sizeName}`, componentList);
    }

    public GetLogo(scene:Scene) : AbstractMesh {
        return scene.getMeshByName(this.huismanLogo);
    }
    
    public GetLogoPanel(scene:Scene) : AbstractMesh {
        return scene.getMeshByName(this.huismanLogoPanel);
    }

    public GetGrassModel(scene:Scene) : AbstractMesh {
        return scene.getMeshByName(this.grassModel);
    }

    public GetTreeCircular(scene:Scene) : AbstractMesh {
        return scene.getMeshByName(this.treeCircular);
    }

    public GetTree(scene:Scene, treeMaterial:TreeMaterial) : AbstractMesh {
        return scene.getMeshByName(this.tree + "_" + treeMaterial);
    }
    
    public GetBush(scene:Scene) : AbstractMesh {
        return scene.getMeshByName(this.bush);
    }

    public async GetMeshClump(scene:Scene, rootUrl:string, meshName:string, componentList:Array<MeshComponent>) : Promise<AbstractMesh>{
        let mainMesh = scene.getMeshByName(meshName + componentList[0].name);
        if(mainMesh === null) {
            for(const component of componentList){
                if(mainMesh === null){
                    let mainMeshName = await this.LoadSingleMesh(scene, rootUrl, meshName + component.name, ".obj", component.material);
                    mainMesh = scene.getMeshByName(mainMeshName);
                }
                else {
                    await this.LoadSingleMesh(scene, rootUrl, meshName + component.name, ".obj", component.material, null, mainMesh);
                }
            }
        }
        return mainMesh;
    }

    public async LoadRoofs(scene :Scene, roofType: RoofType, rootUrl:string){
        var roofMaterial = this.materialSupplier.GetRoofMaterial();
        await this.LoadSingleMesh(scene, rootUrl, "R" + this.GetRoofMeshFileExtensionFromType(roofType), ".obj" , roofMaterial);
    }
    public async LoadRoofDetails(scene :Scene, rootUrl:string){
        await this.LoadSingleMesh(scene, this.roofsRootUrl, this.roofFrontPanelFileName, ".obj", this.materialSupplier.GetPlasticMaterial());
        await this.LoadSingleMesh(scene, this.roofsRootUrl, this.roofTopFileName, ".obj", this.materialSupplier.GetPlasticMaterial());
    }
    public async LoadGutters(scene :Scene, rootUrl:string){
        var gutterMaterial = this.materialSupplier.GetGutterMaterial();
        await this.LoadSingleMesh(scene, rootUrl, this.gutterPipeFileName, ".obj", gutterMaterial);
        await this.LoadSingleMesh(scene, rootUrl, this.gutterFileName, ".obj", gutterMaterial);
    }

    public async LoadDetails(scene :Scene){
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.huismanLogo, ".obj", this.materialSupplier.GetPlasticMaterial());
        let blackMat = new StandardMaterial("", scene);
        blackMat.diffuseColor = new Color3(0,0,0);
        blackMat.emissiveColor = new Color3(0,0,0);
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.huismanLogoPanel, ".obj", blackMat);
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.grassModel, ".obj", this.materialSupplier.GetGrassModelMaterial());
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.treeCircular, ".obj", this.materialSupplier.GetCircularTreeMaterial());
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.tree + "_1", ".obj", this.materialSupplier.GetTreeMaterial(TreeMaterial.One));
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.tree + "_2", ".obj", this.materialSupplier.GetTreeMaterial(TreeMaterial.Two));
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.tree + "_3", ".obj", this.materialSupplier.GetTreeMaterial(TreeMaterial.Three));
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.bush, ".obj", this.materialSupplier.GetBushMaterial());
    }

    public async LoadWalls(scene :Scene, wallType: WallType){
        //Load regular wall
        await this.LoadSingleMesh(scene, this.wallRootUrl, this.wallFileName + this.GetWallMeshFileExtensionFromType(wallType) + "_Big", ".obj", this.materialSupplier.GetWallMaterial(wallType), "W");
        //Load big wall
        await this.LoadSingleMesh(scene, this.wallRootUrl, this.wallFileName + this.GetWallMeshFileExtensionFromType(wallType), ".obj", this.materialSupplier.GetWallMaterial(wallType), "W");
    }

    public async LoadSpant(scene :Scene){
        this.spant = await this.LoadSingleMesh(scene, this.detailsRootUrl, this.spantFileName, ".obj", this.materialSupplier.GetSpantMaterial());
        this.spantBottom =  await this.LoadSingleMesh(scene, this.detailsRootUrl, this.spantBottomFileName, ".obj", null);
    }
    
    public async LoadSmallWallDetail(scene :Scene){
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.smallWallDetailFileName, ".obj", null/*TODO*/);
        await this.LoadSingleMesh(scene, this.detailsRootUrl, this.smallWallDetailCornerFileName, ".obj", null/*TODO*/);
    }

    public async LoadSmallWall(scene :Scene, smallWallType: SmallWallType){
        await this.LoadSingleMesh(scene, this.wallRootUrl, this.wallFileName + this.GetSmallWallMeshFileExtensionFromType(smallWallType), ".obj", this.materialSupplier.GetSmallWallMaterial(smallWallType, SmallWallMaterial.SmoothConcrete), "S");
    }

    public async LoadPillar(scene :Scene){
        await this.LoadSingleMesh(scene, this.wallRootUrl, this.pillarFileName, ".obj", this.materialSupplier.GetPillarMaterial());
    }

    GetRoofMeshFileExtensionFromType(roofType:RoofType) : string{
        switch(roofType){
            case RoofType.SandwichPanelCorrugatedIron:
                return RoofType.CorrugatedIron.toString();
            case RoofType.LarssenSheetPiling:
            case RoofType.LarssenSheetPilingAntiCondense:
                return RoofType.SandwichPanelTrapezium.toString();
            case RoofType.SandwichPanelTrapezium:
                return RoofType.LarssenSheetPiling.toString();
            default:
                return roofType.toString();
        }
    }

    GetWallMeshFileExtensionFromType(wallType:WallType) : string{
        switch(wallType){
            case WallType.ClapboardAndSandwichPanel:
                return WallType.Clapboard.toString();
            default:
                return wallType.toString();
        }
    }

    GetSmallWallMeshFileExtensionFromType(smallWallType:SmallWallType) : string{
        switch(smallWallType){
            case SmallWallType.ConcreteIsolated:
                return SmallWallType.Concrete.toString();
            case SmallWallType.DampProof:
                return SmallWallType.Concrete.toString();
            default:
                return smallWallType.toString();
        }
    }

    public async LoadSingleMesh(scene :Scene, rootUrl:string, fileName:string, extension:string, material:Material, assetNamePrefix?:string, parentMesh:AbstractMesh = null, assetName?:string): Promise<string> {
        if(assetNamePrefix === undefined || assetNamePrefix === null){
            assetNamePrefix = "";
        }
        var meshName = assetNamePrefix + fileName;
        if(assetName !== undefined && assetNamePrefix !== null){
            meshName = assetName;
        }
        await SceneLoader.ImportMeshAsync("", "/" + rootUrl, fileName + extension, scene).then((result) => {
            if(result.meshes.length == 0){
                console.error("No mesh found in file:" + rootUrl + fileName + extension);
            }
            if(result.meshes.length > 1){
                console.error("Multiple meshes were found in file:" + rootUrl + fileName + extension);
            }
            for(var mesh of result.meshes) {
                mesh.isVisible = false;
                mesh.name = meshName;
                mesh.material = material;
                if(parentMesh !== null){
                    mesh.setParent(parentMesh);
                }
                mesh.freezeWorldMatrix();
            }
        });
        return meshName;
    }
}