export enum PlaceableMeshType {
    DoorEntry_103x216,
    DoorEntry_103x240,
    DoorEntry_203x216,
    DoorEntry_203x240,
    DoorRegular_103x216,
    DoorRegular_103x240,
    DoorRegular_203x216,
    DoorRegular_203x240,
    DoorRegularAlternative_100x2125,
    OverHeadDoorWindowedHeadWalls_300x275,
    OverHeadDoorWindowedHeadWalls_300x300,
    OverHeadDoorWindowedHeadWalls_350x350,
    OverHeadDoorWindowedHeadWalls_400x375,
    OverHeadDoorWindowedHeadWalls_400x400,
    OverHeadDoorWindowedHeadWalls_400x425,
    OverHeadDoorWindowedHeadWalls_500x400,
    OverHeadDoorWindowedHeadWalls_500x425,
    OverHeadDoorWindowedHeadWalls_500x450,
    OverHeadDoorWindowedHeadWalls_500x475,
    OverHeadDoorWindowedHeadWalls_600x450,
    OverHeadDoorWindowedHeadWalls_600x475,
    OverHeadDoorWindowedHeadWalls_600x500,
    OverHeadDoorWindowedSideWalls_300x275,
    OverHeadDoorWindowedSideWalls_300x300,
    OverHeadDoorWindowedSideWalls_455x275,
    OverHeadDoorWindowedSideWalls_455x375,
    OverHeadDoorWindowedSideWalls_455x405,
    OverHeadDoorWindowedSideWalls_455x425,
    SlidingDoorHeadWalls_150x225,
    SlidingDoorHeadWalls_200x225,
    SlidingDoorHeadWalls_250x250,
    SlidingDoorHeadWalls_275x275,
    SlidingDoorHeadWalls_300x285,
    SlidingDoorHeadWalls_300x300,
    SlidingDoorSideWalls_150x225,
    SlidingDoorSideWalls_200x225,
    SlidingDoorSideWalls_250x250,
    SlidingDoorSideWalls_275x275,
    SlidingDoorSideWalls_300x285,
    SlidingDoorSideWalls_300x300,
    SlidingDoubleDoorHeadWalls_300x300,
    SlidingDoubleDoorHeadWalls_400x400,
    SlidingDoubleDoorHeadWalls_400x425,
    SlidingDoubleDoorHeadWalls_500x400,
    SlidingDoubleDoorHeadWalls_500x425,
    SlidingDoubleDoorHeadWalls_500x435,
    SlidingDoubleDoorHeadWalls_500x450,
    SlidingDoubleDoorHeadWalls_500x455,
    SlidingDoubleDoorHeadWalls_500x475,
    SlidingDoubleDoorHeadWalls_600x450,
    SlidingDoubleDoorHeadWalls_600x475,
    SlidingDoubleDoorHeadWalls_600x500,
    SlidingDoubleDoorSideWalls_500x385,
    SlidingDoubleDoorSideWalls_500x420,
    SlidingDoubleDoorSideWalls_500x440,
    WindowTurn_101x112,
    WindowTurn_101x135,
    WindowTurn_201x112,
    WindowTurn_201x135,
    WindowTurn_301x112,
    WindowTurn_301x135,
    WindowTurn_401x112,
    WindowTurn_401x135,
    WindowStatic_101x112,
    WindowStatic_101x135,
    WindowStatic_201x112,
    WindowStatic_201x135,
    WindowStatic_301x112,
    WindowStatic_301x135,
    WindowStatic_401x112,
    WindowStatic_401x135
};

