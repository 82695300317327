import { RoofType } from './Enums/roofType';
import { SmallWallType } from './Enums/smallWallType';
import { WallType } from './Enums/wallType';
import { TreeMaterial } from './Enums/treeMaterial';
import * as concreteBSMVulcanRed from '../Materials/Scene/ConcreteBrickVulcanRed.json'
import * as concreteBSMRubyRed from '../Materials/Scene/ConcreteBrickRubyRed.json'
import * as concreteBSMVictoriaBlack from '../Materials/Scene/ConcreteBrickVictoriaBlack.json'
import * as concreteSilex from '../Materials/Scene/ConcreteSilax.json'
import * as concreteSmoothConcrete from '../Materials/Scene/ConcreteConcrete.json'
import * as roofDamPlate from '../Materials/Scene/wallDamPlate.json'
import * as roofGolfPlate from '../Materials/Scene/roofGolfPlate.json'
import * as roofSandwichGolf from '../Materials/Scene/roofSandwichGolf.json'
import * as roofSandwichTrapezium from '../Materials/Scene/roofSandwichTrapezium.json'
import * as trasRaam from '../Materials/Scene/wallDamPlate.json'
import * as wallDamPlate from '../Materials/Scene/wallDamPlate.json'
import * as wallPotLidPlate from '../Materials/Scene/wallPotLidPlate.json'
import * as wallPotLidPlateAndSandwichPlate from '../Materials/Scene/wallPotLidPlateAndSandwichPlate.json'
import * as wallSandwichPlate from '../Materials/Scene/wallSandwichPlate.json'
import * as spantMaterial from '../Materials/Scene/SpantMaterial.json'
import * as gutterMaterial from '../Materials/Scene/GutterMaterial.json'
import * as pillarMaterial from '../Materials/Scene/PillarMaterial.json'
import * as grassMaterial from '../Materials/Scene/Grass.json'
import * as grassModelMaterial from '../Materials/Scene/Grass_Model.json'
import * as metalBasicMaterial from '../Materials/Scene/Metal_Basic.json'
import * as plasticMaterial from '../Materials/Scene/Plastic_Basic.json'
import * as glassMaterial from '../Materials/Scene/Glass_Basic.json'
import * as groundTileMaterial from '../Materials/Scene/GroundTile.json'
import * as circularTreeMaterial from '../Materials/Scene/Tree_Sprite.json'
import * as treeMaterial1 from '../Materials/Scene/Tree_1.json'
import * as treeMaterial2 from '../Materials/Scene/Tree_2.json'
import * as treeMaterial3 from '../Materials/Scene/Tree_3.json'
import * as bushMaterial from '../Materials/Scene/Bush.json'
import ShedMeshComposer from './shedMeshComposer';
import { SmallWallMaterial } from './Enums/smallWallMaterial';
import { Material, PBRMaterial, StandardMaterial, Scene, Texture, Color3 } from '@babylonjs/core';

export default class ShedMaterialSupplier {
    private testMaterial:Material;

    private smallWallMaterialConcreteBSMVulcanRed:PBRMaterial;
    private smallWallMaterialConcreteBSMRubyRed:PBRMaterial;
    private smallWallMaterialConcreteBSMVictoriaBlack:PBRMaterial;
    private smallWallMaterialConcreteSmoothConcrete:PBRMaterial;
    private smallWallMaterialConcreteSilex:PBRMaterial;
    private smallWallMaterialTrasRaam:PBRMaterial;

    private wallMaterialDamPlate:Material;
    private wallMaterialPotLidPlate:Material;
    private wallMaterialSandwichPlate:Material;

    private wallNoBumpMaterialDamPlate:Material;
    private wallNoBumpMaterialSandwichPlate:Material;

    private roofMaterialDamPlate:Material;
    private roofMaterialGolfPlate:Material;
    private roofMaterialSandwichGolf:Material;
    private roofMaterialSandwichTrapezium:Material;

    private gutterMaterial:Material;
    private spantMaterial:Material;
    private pillarMaterial:Material;
    
    private grassMaterial:Material;
    private grassModelMaterial:Material;
    private metalBasicMaterial:Material;

    private plasticMaterial:Material;
    private glassMaterial:Material;

    private groundTileMaterial:StandardMaterial;

    private circularTreeMaterial:Material;

    private treeMaterials:Array<Material>;

    private bushMaterial:StandardMaterial;

    private scene:Scene;

    constructor(scene: Scene) {
        this.scene = scene;
    }
    
    public async LoadMaterials(scene:Scene) : Promise<void> {
        this.smallWallMaterialConcreteBSMVulcanRed = PBRMaterial.Parse(concreteBSMVulcanRed, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteBSMRubyRed = PBRMaterial.Parse(concreteBSMRubyRed, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteBSMVictoriaBlack = PBRMaterial.Parse(concreteBSMVictoriaBlack, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteSmoothConcrete = PBRMaterial.Parse(concreteSmoothConcrete, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialConcreteSilex = PBRMaterial.Parse(concreteSilex, scene, "./../Materials/Scene/"); 

        this.smallWallMaterialTrasRaam = PBRMaterial.Parse(trasRaam, scene, "./../Materials/Scene/"); 
        this.smallWallMaterialTrasRaam.freeze();

        this.wallMaterialDamPlate = PBRMaterial.Parse(wallDamPlate, scene, "./../Materials/Scene/"); 
        this.wallMaterialDamPlate.freeze();
        this.wallMaterialPotLidPlate = PBRMaterial.Parse(wallPotLidPlate, scene, "./../Materials/Scene/"); 
        this.wallMaterialPotLidPlate.freeze();
        this.wallMaterialSandwichPlate = PBRMaterial.Parse(wallSandwichPlate, scene, "./../Materials/Scene/"); 
        this.wallMaterialSandwichPlate.freeze();

        this.wallNoBumpMaterialDamPlate = PBRMaterial.Parse(wallDamPlate, scene, "./../Materials/Scene/"); 
        (this.wallNoBumpMaterialDamPlate as PBRMaterial).bumpTexture = null;
        this.wallNoBumpMaterialDamPlate.freeze();
        this.wallNoBumpMaterialSandwichPlate = PBRMaterial.Parse(wallSandwichPlate, scene, "./../Materials/Scene/"); 
        (this.wallNoBumpMaterialSandwichPlate as PBRMaterial).bumpTexture = null;
        this.wallNoBumpMaterialSandwichPlate.freeze();

        this.roofMaterialDamPlate = PBRMaterial.Parse(roofDamPlate, scene, "./../Materials/Scene/"); 
        (this.roofMaterialDamPlate as PBRMaterial).bumpTexture = null;
        this.roofMaterialDamPlate.freeze();
        this.roofMaterialGolfPlate = PBRMaterial.Parse(roofGolfPlate, scene, "./../Materials/Scene/"); 
        this.roofMaterialGolfPlate.freeze();
        this.roofMaterialSandwichGolf = PBRMaterial.Parse(roofSandwichGolf, scene, "./../Materials/Scene/"); 
        this.roofMaterialSandwichGolf.freeze();
        this.roofMaterialSandwichTrapezium = PBRMaterial.Parse(roofSandwichTrapezium, scene, "./../Materials/Scene/"); 
        this.roofMaterialSandwichTrapezium.freeze();

        this.gutterMaterial = PBRMaterial.Parse(gutterMaterial, scene, "./../Materials/Scene/"); 
        this.gutterMaterial.freeze();
        this.pillarMaterial = PBRMaterial.Parse(pillarMaterial, scene, "./../Materials/Scene/"); 
        this.pillarMaterial.freeze();
        this.spantMaterial = PBRMaterial.Parse(spantMaterial, scene, "./../Materials/Scene/"); 
        this.spantMaterial.freeze();

        this.grassMaterial = StandardMaterial.Parse(grassMaterial, scene, "./../Materials/Scene/");
        this.grassMaterial.freeze();
        
        this.grassModelMaterial = StandardMaterial.Parse(grassModelMaterial, scene, "./../Materials/Scene/");
        this.grassModelMaterial.freeze();

        this.metalBasicMaterial = PBRMaterial.Parse(metalBasicMaterial, scene, "./../Materials/Scene/");
        this.metalBasicMaterial.freeze();

        this.plasticMaterial = PBRMaterial.Parse(plasticMaterial, scene, "./../Materials/Scene/");
        this.plasticMaterial.freeze();
        
        this.glassMaterial = PBRMaterial.Parse(glassMaterial, scene, "./../Materials/Scene/");
        this.glassMaterial.freeze();
        
        this.groundTileMaterial = StandardMaterial.Parse(groundTileMaterial, scene, "./../Materials/Scene/");
        //this.groundTileMaterial.freeze(); do not uncomment, this causes the floor to display differently initially

        this.circularTreeMaterial = PBRMaterial.Parse(circularTreeMaterial, scene, "./../Materials/Scene/");
        this.circularTreeMaterial.freeze();

        this.treeMaterials = new Array<Material>();
        let treeMaterial_1 = StandardMaterial.Parse(treeMaterial1, scene, "./../Materials/Scene/");
        treeMaterial_1.freeze();
        this.treeMaterials.push(treeMaterial_1);

        let treeMaterial_2 = StandardMaterial.Parse(treeMaterial2, scene, "./../Materials/Scene/");
        treeMaterial_2.freeze();
        this.treeMaterials.push(treeMaterial_2);

        let treeMaterial_3 = StandardMaterial.Parse(treeMaterial3, scene, "./../Materials/Scene/");
        treeMaterial_3.freeze();
        this.treeMaterials.push(treeMaterial_3);

        this.bushMaterial = StandardMaterial.Parse(bushMaterial, scene, "./../Materials/Scene/");
        this.bushMaterial.freeze();
    }

    
    public UpdateSmallWallMaterialUVs(shedMeshComposer:ShedMeshComposer, smallWallType:SmallWallType, uvScale:number, smallWallMaterial: SmallWallMaterial){
        switch(smallWallType){
            case SmallWallType.Concrete:
            case SmallWallType.ConcreteIsolated:
                switch(smallWallMaterial){
                    case SmallWallMaterial.BSMRubyRed:
                    case SmallWallMaterial.BSMVulcanRed:
                    case SmallWallMaterial.BSMVictoriaBlack:
                        let concreteMaterial = this.getSmallWallConcrete(smallWallMaterial);
                        concreteMaterial.unfreeze();
                        ((concreteMaterial as PBRMaterial).albedoTexture as Texture).vScale = uvScale;
                        shedMeshComposer.unfrozenMaterialNames.push(concreteMaterial.name);
                    case SmallWallMaterial.Silex:
                        let silexMaterial = this.GetSmallWallMaterial(smallWallType, SmallWallMaterial.Silex);
                        silexMaterial.unfreeze();
                        ((silexMaterial as PBRMaterial).albedoTexture as Texture).vScale = uvScale * 5;
                        ((silexMaterial as PBRMaterial).albedoTexture as Texture).uScale = uvScale * 5;
                        shedMeshComposer.unfrozenMaterialNames.push(silexMaterial.name);
                        break;
                }
                break;
            case SmallWallType.DampProof:
                    let dampProofMaterial = this.GetSmallWallMaterial(smallWallType, SmallWallMaterial.Silex);
                    dampProofMaterial.unfreeze();
                    if(shedMeshComposer.Width % 2 == 1) {
                        ((dampProofMaterial as PBRMaterial).bumpTexture as Texture).uOffset = 0.5;
                    }
                    else{
                        ((dampProofMaterial as PBRMaterial).bumpTexture as Texture).uOffset = 0;
                    }
                    shedMeshComposer.unfrozenMaterialNames.push(dampProofMaterial.name);
                break;
        }
    }

    private getSmallWallConcrete(smallWallMaterial:SmallWallMaterial):PBRMaterial {
        switch(smallWallMaterial){
            case SmallWallMaterial.BSMVulcanRed:
                return this.smallWallMaterialConcreteBSMVulcanRed;
                break;
            case SmallWallMaterial.BSMRubyRed:
                return this.smallWallMaterialConcreteBSMRubyRed;
                break;
            case SmallWallMaterial.BSMVictoriaBlack:
                return this.smallWallMaterialConcreteBSMVictoriaBlack;
                break;
            case SmallWallMaterial.SmoothConcrete:
                return this.smallWallMaterialConcreteSmoothConcrete;
                break;
            case SmallWallMaterial.Silex:
                return this.smallWallMaterialConcreteSilex;
                break;
        }
    }

    public GetSmallWallMaterial(smallWallType:SmallWallType, smallWallMaterial:SmallWallMaterial):Material{
        switch(smallWallType) {
            case SmallWallType.Concrete:
            case SmallWallType.ConcreteIsolated:
                return this.getSmallWallConcrete(smallWallMaterial);
            case SmallWallType.DampProof:
                return this.smallWallMaterialTrasRaam;
        }
    }

    public GetWallMaterial(wallType:WallType):Material{
        switch(wallType){
            case WallType.LarssenSheetPiling:
                return this.wallMaterialDamPlate;
            case WallType.Clapboard:
                return this.wallMaterialPotLidPlate;
            case WallType.ClapboardAndSandwichPanel:
                return this.wallMaterialPotLidPlate;
            case WallType.SandwichPanel:
                return this.wallMaterialSandwichPlate;
        }
    }

    public GetWallMaterialColoured(wallType:WallType, color:Color3, bump:boolean):Material{
        let material = this.GetWallMaterial(wallType).clone(wallType.toString() + color.toString());
        (material as PBRMaterial).albedoColor = color;
        if(!bump){
            (material as PBRMaterial).bumpTexture = null;
        }
        return material;
    }

    public GetWallWithoutBumpMaterial(wallType:WallType):Material{
        switch(wallType){
            case WallType.LarssenSheetPiling:
                return this.wallNoBumpMaterialDamPlate;
            case WallType.Clapboard:
                return this.wallMaterialPotLidPlate;
            case WallType.ClapboardAndSandwichPanel:
                return this.wallMaterialPotLidPlate;
            case WallType.SandwichPanel:
                return this.wallNoBumpMaterialSandwichPlate;
        }
    }
    
    public GetRoofMaterial():Material{
        return this.roofMaterialDamPlate;
    }
    public GetPannelMaterial(wallType:WallType):Material{
        return this.GetWallMaterial(wallType);
    }
    public GetPillarMaterial():Material{
        return null;
    }
    public GetGutterMaterial():Material{
        return this.gutterMaterial;
    }
    public GetTestMaterial():Material{
        return this.testMaterial;
    }
    public GetSpantMaterial():Material{
        return this.spantMaterial;
    }
    public GetGrassMaterial():Material{
        return this.grassMaterial;
    }
    public GetGrassModelMaterial():Material{
        return this.grassModelMaterial;
    }
    public GetMetalBasicMaterial():Material{
        return this.metalBasicMaterial;
    }
    public GetPlasticMaterial():Material{
        return this.plasticMaterial;
    }
    public GetGlassMaterial():Material{
        return this.glassMaterial;
    }
    public GetCircularTreeMaterial():Material{
        return this.circularTreeMaterial;
    }
    public GetTreeMaterial(treeMaterial:TreeMaterial):Material{
        return this.treeMaterials[treeMaterial-1];
    }
    public GetBushMaterial():Material{
        return this.bushMaterial;
    }
    public GetGroundTileMaterial():Material{
        return this.groundTileMaterial;
    }
}