import { Color3, Vector2 } from "@babylonjs/core";

export default class UtilityFunctions {
    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }

    static hexToColor3(h: string): Color3 {
        let r: any = 0, g: any = 0, b: any = 0;

        if (h.length == 4) {
            r = "0x" + h[1] + h[1];
            g = "0x" + h[2] + h[2];
            b = "0x" + h[3] + h[3];

        } else if (h.length == 7) {
            r = "0x" + h[1] + h[2];
            g = "0x" + h[3] + h[4];
            b = "0x" + h[5] + h[6];
        }

        r = +(r / 255);
        g = +(g / 255);
        b = +(b / 255);

        return new Color3(r, g, b);
    }

    static GetPointOfIntersection(p1:Vector2, p2:Vector2, n1:Vector2, n2:Vector2)
    {
        let p1End:Vector2 = p1.add(n1); // another point in line p1->n1
        let p2End:Vector2 = p2.add(n2); // another point in line p2->n2

        let m1 = (p1End.y - p1.y) / (p1End.x - p1.x); // slope of line p1->n1
        let m2 = (p2End.y - p2.y) / (p2End.x - p2.x); // slope of line p2->n2

        let b1 = p1.y - m1 * p1.x; // y-intercept of line p1->n1
        let b2 = p2.y - m2 * p2.x; // y-intercept of line p2->n2

        let px = (b2 - b1) / (m1 - m2); // collision x
        let py = m1 * px + b1; // collision y

        return new Vector2(px, py); // return statement
    }

    static detectAndroid():boolean {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
}