import { Material } from "@babylonjs/core";

export default class MeshComponent {
    public name: string;
    public material:Material;

    /**
     *
     */
    constructor(name:string, material:Material) {
        this.name = name;
        this.material = material;
    }
}
